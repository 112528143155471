import { Injectable, EventEmitter } from '@angular/core';

import { User } from 'src/app/models/user.model';
import { Order } from 'src/app/models/order.model';
import { Banwire, BanwireCust, BanwireShip, BanwireProduct, BanwireRecurring } from 'src/app/models/banwire.model';

import { UtilService } from 'src/app/services/util/util.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BanwireService {

  SW;
  banwireCust: BanwireCust;
  banwireShip: BanwireShip;
  banwireRecurring: BanwireRecurring;
  banwire: Banwire;

  URL: string = "https://ncvznkh234.execute-api.us-east-1.amazonaws.com/v1";

  constructor(
    private utilService: UtilService,
    private http: HttpClient
  ) {
    this.banwireCust = new BanwireCust();
    this.banwireShip = new BanwireShip();

    this.banwireRecurring = new BanwireRecurring();
    this.banwire = new Banwire();
    this.banwire.items = new Array<BanwireProduct>();
    this.banwire.cust = new BanwireCust();
    this.banwire.ship = new BanwireShip();
  }

  public createCharge(user: User, order: Order) {

    //BanwireCust
    this.banwireCust.fname = user.name;
    if (user.firstSurname) {
      this.banwireCust.mname = user.firstSurname;
    } else {
      this.banwireCust.mname = "";
    }

    if (user.secondSurname) {
      this.banwireCust.lname = user.secondSurname;
    } else {
      this.banwireCust.lname = "";
    }

    this.banwireCust.email = user.email;
    if (user.cellphone) {
      this.banwireCust.phone = user.cellphone;
    } else {
      this.banwireCust.phone = "";
    }

    this.banwireCust.addr = order.address.street + " " + order.address.outdoorExt;
    if (order.address.outdoorInt) {
      this.banwireCust.addr += " int: " + order.address.outdoorInt;
    } else {
      this.banwireCust.addr += " int: s/n";
    }
    this.banwireCust.city = order.address.city;
    this.banwireCust.zip = order.address.postalCode;
    this.banwireCust.country = "MEX";
    this.banwireCust.state = "";

    //BanwireShip
    this.banwireShip.addr = order.address.street + " " + order.address.outdoorExt;
    if (order.address.outdoorInt) {
      this.banwireShip.addr += " int: " + order.address.outdoorInt;
    } else {
      this.banwireShip.addr += " int: s/n";
    }
    this.banwireShip.city = order.address.city;
    this.banwireShip.country = "MEX";
    this.banwireShip.zip = order.address.postalCode;
    this.banwireShip.state = "";

    //BanwireRecurring
    this.banwireRecurring.interval = "month";
    this.banwireRecurring.limit = 1;
    this.banwireRecurring.start = this.utilService.formatDate(order.dateCreated);
    this.banwireRecurring.total = order.subtotal.toString();


    //BanwireObj
    this.banwire.sandbox = true;
    this.banwire.user = "pruebasbw";
    this.banwire.title = "El Salón Virtual";
    this.banwire.reference = order.orderNumber;
    //this.banwire.reference = "testref01";
    this.banwire.concept = "pago de prueba";
    this.banwire.currency = "MXN";
    this.banwire.international = false;
    this.banwire.secure3D = false;
    this.banwire.exchangeRate = "";
    this.banwire.total = order.total.toString();
    this.banwire.items = [];
    order.lstProducts.forEach((product) => {
      var banwireProduct = new BanwireProduct();
      banwireProduct.name = product.name;
      banwireProduct.desc = product.description;
      banwireProduct.qty = product.quantity;
      banwireProduct.unitPrice = product.price;
      this.banwire.items.push(banwireProduct);
    });

    this.banwire.cust = this.banwireCust;
    this.banwire.ship = this.banwireShip;
    this.banwire.time = 900;
    this.banwire.paymentOptions = "visa, mastercard, amex, paycash,speifast";
    this.banwire.reviewOrder = true;
    this.banwire.showShipping = true;
    this.banwire.notifyUrl = "https://ncvznkh234.execute-api.us-east-1.amazonaws.com/v1/pay/banwire";
    //this.banwire.notifyUrl = "https://www.mipagina.com/recibir.php"
      return this.banwire;
  }

  public checkPay(idOrder): Observable<any> {
    return this.http.get(this.URL + '/pay/checkPay?orderNumber=' + idOrder)
      .pipe(
        map(
          results => {
          return results;
        }
        )
      );
  }

}
