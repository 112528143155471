export class BanwireProduct {

    name: string;
    qty: number;
    desc: string;
    unitPrice: number;

    constructor() {

        this.name = this.name;
        this.qty = this.qty;
        this.desc = this.desc;
        this.unitPrice = this.unitPrice;

    }

}

export class BanwireCust {

    fname: string; //Nombre del comprador
    mname: string; //Apellido paterno del comprador
    lname: string; //Apeliido materno del comprador
    email: string; //Email del comprador
    phone: string; //Número telefónico del comprador
    addr: string; //Dirección del comprador (calle y número)
    city: string; //Ciudad del comprador
    state: string; //Estado del comprador (2 dígitos de acuerdo al formato ISO)
    country: string; //País del comprador (3 dígitos de acuerdo al formato ISO)
    zip: string; //Código de postal del comprador

    constructor() {
        this.fname = this.fname;
        this.mname = this.mname;
        this.lname = this.lname;
        this.email = this.email;
        this.phone = this.phone;
        this.addr = this.addr;
        this.city = this.city;
        this.state = this.state;
        this.country = this.country;
        this.zip = this.zip;
    }

}

export class BanwireShip {

    addr: string; //Dirección de envío
    city: string; //Ciudad de envío
    state: string; //Estado de envío (2 dígitos de acuerdo al formato ISO)
    country: string; //País de envío (3 dígitos de acuerdo al formato ISO)
    zip: string; //Código de postal del envío

    constructor() {
        this.addr = this.addr;
        this.city = this.city;
        this.state = this.state;
        this.country = this.country;
        this.zip = this.zip;
    }

}

export class BanwireRecurring {

    interval: string;// Cada cuanto se ejecutará el pago "month","year" o un entero representando numero de días
    limit: number;// Opcional: Limitar el número de pagos (si no se pone entonces no tendrá limite)
    start: string; // Opcional: Fecha del primer cargo (en caso de no especificar se ejecutará de inmediato) Formato YYYY-MM-DD
    total: string // Opcional: En caso de que los pagos subsecuentes (después del primero) tengan un monto distinto al inicial

    constructor() {

        this.interval = this.interval;
        this.limit = this.limit;
        this.start = this.start;
        this.total = this.total;

    }

}

export class Banwire {

    sandbox: boolean; // Quitar o establecer a false cuando pase a produccion
    user: string; // Nombre de usuario de Banwire
    title: string; // Titulo de la entana
    reference: string; // Referencia
    concept: string; // Concepto
    currency: string; // Opcional: Moneda
    international: boolean; // Opcional: Configuracion especial para pagos internacionales
    secure3D: boolean; // Opcional: Habilitar Validacion 3D Secure
    exchangeRate: string; // Opcional: Tipo de cambio definido por el comercio (En caso de seleccionar una moneda que requiera mostrar el tipo de cambio a MXN. Solo informativo). Ejemplo: 15.00
    total: string; // Total de la compra
    time: number; // Cierre automático de la ventana. Por defecto es indefinido y no cerrará la ventana. (el valor es en segundos. valor minimo requerido 60 segundos.) Ejemplo de cierre de ventana automáticamente en 15 minutos
    paymentOptions: string; // Opciones de pago, por defecto es "all". Puede incluir varias opciones separadas por comas [visa, mastercard, amex, paycash, oxxo,speifast,all]
    reviewOrder: boolean; // Mostrar o no pagina de resumen de compra
    showShipping: boolean; // Mostrar o no mostrar los campos de envio
    notifyUrl: string; // URL donde se van a enviar todas las notificaciones por HTTP POST de manera asoncrónica
    //months: number []; // Opcional: Meses sin intereses
    //recurring: BanwireRecurring; // Solamente para pagos recurrentes o suscripciones
    items: BanwireProduct[]; // Arreglo con los items de compra
    cust: BanwireCust;
    ship: BanwireShip;

    constructor() {

        this.sandbox = this.sandbox;
        this.user = this.user;
        this.title = this.title;
        this.reference = this.reference;
        this.concept = this.concept;
        this.currency = this.currency;
        this.international = this.international;
        this.secure3D = this.secure3D;
        this.exchangeRate = this.exchangeRate;
        this.total = this.total;
        this.time = this.time;
        this.paymentOptions = this.paymentOptions;
        this.reviewOrder = this.reviewOrder;
        this.showShipping = this.showShipping;
        this.notifyUrl = this.notifyUrl;
        //this.months = this.months;
        //this.recurring = new BanwireRecurring();
        this.items = new Array<BanwireProduct>();
        this.cust = new BanwireCust();
        this.ship = new BanwireShip();

    }

}